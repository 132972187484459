<template>
  <div>
    <b-tabs
      v-model="tabIndex"
      vertical
      content-class="col-12 col-lg-10 mt-1 mt-md-0"
      pills
      lazy
      nav-wrapper-class="col-lg-2 col-12"
      nav-class="nav-left"
    >
      <!-- tab: 個人資料 -->
      <b-tab active>
        <template #title>
          <b-img
            rounded
            height="18"
            width="18"
            class="mr-50 menu-icon"
            :src="require('@/assets/images/pages/customer/ui-user.svg')"
          />
          <span class="font-weight-bold">個人資料</span>
        </template>

        <profile-info
          @refresh-data="refreshProfileInfoData"
        />
      </b-tab>

      <!-- tab: 工作階段 -->
      <b-tab>
        <template #title>
          <b-img
            height="18"
            width="18"
            class="mr-50 menu-icon"
            :src="require('@/assets/images/pages/customer/ui-login.svg')"
          />
          <span class="font-weight-bold">工作階段</span>
        </template>

        <profile-workphase
          @refresh-data="refreshProfileInfoData"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import { BTabs, BTab, BImg } from 'bootstrap-vue'

import store from '@/store'
import ProfileInfo from './ProfileInfo.vue'
import ProfileWorkphase from './ProfileWorkphase.vue'
import { useProfileFun } from './useProfile'
import useStoreModule from './useStoreModule'
import router from '@/router'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BTabs,
    BTab,
    BImg,

    ProfileInfo,
    ProfileWorkphase,
  },
  data() {
    return {
      tabIndex: 0,
      title: router.currentRoute.meta.pageTitle,
    }
  },
  setup() {
    const PROFILE_USER_STORE_MODULE_NAME = 'user-profile'

    if (!store.hasModule(PROFILE_USER_STORE_MODULE_NAME)) store.registerModule(PROFILE_USER_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(PROFILE_USER_STORE_MODULE_NAME)) store.unregisterModule(PROFILE_USER_STORE_MODULE_NAME)
    })

    const {
      refreshProfileInfoData,
    } = useProfileFun()

    refreshProfileInfoData()

    return {
      refreshProfileInfoData,
    }
  },
}
</script>
